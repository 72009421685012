/** @file
*
* Se encarga sincronizar las columnas del header de la tabla fiji
* con el ancho de las celdas de la tabla
*/
import onmount from 'onmount';

const activeObserves = new Map();

onmount('.fiji-index', function () {
  const fijiView = this;
  const fijiId = fijiView.getAttribute('id');

  const table = fijiView.querySelector('.fiji-table');

  syncHeaderColumns({ fijiView, fijiId, tableBody: table });
}, function () {
  const fijiId = this.getAttribute('id');

  removeObserver(fijiId);
});

/**
 * Sincroniza el ancho de las columnas del header con las del body de la tabla,
 * detectando su redimensionamiento
 *
 * @param {Object} options - Objecto necesario para la sincronización.
 * @param {HTMLElement} options.fijiView - Elemento principal de la vista Fiji
 * @param {string} options.fijiId - ID de la vista para rastrear los observadores activos
 * @param {HTMLElement} options.tableBody - Elemento que contiene los registros de la tabla
 */
function syncHeaderColumns({ fijiView, fijiId, tableBody }) {
  const tableObserver = new ResizeObserver(() => {
    const firstTableRow = fijiView.querySelectorAll('tbody:not(.fiji-table-body--empty) > tr:first-of-type > td');
    const headerRow = fijiView.querySelectorAll(`.fiji-index .fiji-header .fiji-header__column`);

    setHeaderColumnsWidth(firstTableRow, headerRow);
  });

  tableObserver.observe(tableBody);

  // Se mapea el observer para eliminarlo cuando el componente se desmonta
  activeObserves.set(fijiId, {
    observer: tableObserver,
    targetElement: tableBody,
  });
}

/**
 * Elimina un observador activo basado en su ID.
 *
 * @param {string} observerId - El ID del observador que se quiere eliminar.
 */
function removeObserver(observerId) {
  const activeObserver = activeObserves.get(observerId);

  if(!activeObserver) return;

  const { observer, targetElement } = activeObserver;

  observer.unobserve(targetElement);
}

/**
 * Establece el ancho de las columnas del header para que coincidan con las celdas de la tabla
 *
 * @param {NodeListOf<HTMLElement>} tableRow - Fila del body de la tabla que representa las celdas
 * @param {NodeListOf<HTMLElement>} headerRow - Las columnas del header que deben ajustarse
 */
function setHeaderColumnsWidth(tableRow, headerRow) {
  for (let index = 0; index < tableRow.length; index++) {
    const cell = tableRow[index];
    const headerColumn = headerRow[index];

    const cellWidth = cell.getBoundingClientRect().width.toFixed(2);

    if(Math.floor(cellWidth) === 0 || !headerColumn?.style) continue;

    headerColumn.style.width = `${cellWidth}px`;
  }
}

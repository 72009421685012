/** @file
 * behaviors para mostrar/ocultar columnas en fiji.
 */
import onmount from 'onmount';

const dropdownMenuOverflow = false;

/**
 * Obtiene el estado inicial de las opciones de visibilidad de columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {Array<boolean>} - Arreglo de booleanos que indica si cada columna está visible o no.
 * */
function getInitialStateOptions($dropdown) {
  const initialStateOtions = $dropdown.find('input[type="checkbox"]').map(function () {
    return this.checked;
  }).get();
  return initialStateOtions;
}

function stopPropagationMenu($dropdown) {
  $dropdown.on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
  });
}

/**
 * Evita que contenedor table-responsive overflow auto corte el dropdown de visibilidad de columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @param {jQuery} $fijiBody - Contenedor de la tabla.
 * @param {jQuery} $submitButton - Botón submit del formulario
 * */
function handleOverflowTable($dropdown) {
  const dropdownMenu = $dropdown.children('.dropdown-menu');
  const closestForm = $dropdown.closest('form');
  const submitButton = closestForm.find('#column-visibility-dropdown__submit');
  const personalizedResourceId = closestForm.attr('data-body-id');
  const $fijiBody = $(`#${personalizedResourceId}.fiji-body`);
  $dropdown.on('show.bs.dropdown', function () {
    const dropdownOffset = $dropdown.offset();
    const dropdownMenuHeight = dropdownMenu.outerHeight();
    const tableHeight = $dropdown.closest('.table-responsive').outerHeight();
    const tableBottom = $dropdown.closest('.table-responsive').offset().top + tableHeight;
    const dropdownBottom = dropdownOffset.top + $dropdown.outerHeight() + dropdownMenuHeight;

    if (dropdownBottom > tableBottom) {
      // Mover el dropdown fuera del contenedor de la tabla para evitar recortes
      dropdownMenu.addClass('dropdown-menu--overflow');
      dropdownMenu.appendTo($fijiBody);
      stopPropagationMenu($fijiBody);
      // Ajustar la posición del menú para que coincida con el botón de apertura
      dropdownMenu.css({
        position: 'absolute',
        top: dropdownOffset.top + $dropdown.outerHeight(),
        left: dropdownOffset.left,
        display: 'block',
      });
    }
    // mover el dropdown al contenedor original antes de enviar el formulario
    submitButton.off('click').on('click', function (event) {
      dropdownMenu.appendTo($dropdown);
      dropdownMenu.css({
        display: 'none',
      });
      event.preventDefault();
      closestForm.submit();
    });
  });

  // Restaurar el dropdown dentro de su contenedor original para mantener el flujo DOM
  $dropdown.on('hide.bs.dropdown', function () {
    dropdownMenu.appendTo($dropdown);
    dropdownMenu.css({
      display: 'none',
    });
  });
}
/**
 * Habilita el botón de aplicar cambios si el usuario cambia la visibilidad de las columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @param {jQuery} $applyButton - Botón submit del dropdown.
 * @param {Array<boolean>} initialStateOptions - Estado inicial de las opciones de visibilidad de columnas.
 * @param {jQuery} optionsContainer - Contenedor de dropdown de opciones que cambia si existe overflow.
 * @returns {void}
 * */
function userChangeOptionVisibility($dropdown) {
  const applyButton = dropdownMenuOverflow ?
    $dropdown.closest('.column-visibility').find('#column-visibility-dropdown__submit') :
    $dropdown.find('#column-visibility-dropdown__submit');
  const initialStateOptions = getInitialStateOptions($dropdown);
  const form = applyButton.closest('form');
  const optionsContainer = dropdownMenuOverflow ?
    $('.fiji-body').find('.dropdown-menu--overflow') :
    $dropdown.find('.dropdown-menu');

  optionsContainer.on('change', 'input[type="checkbox"]', function () {
    const currentStateOptions = getInitialStateOptions(optionsContainer);
    const isDifferent = initialStateOptions.some((value, index) => value !== currentStateOptions[index]);
    applyButton.prop('disabled', !isDifferent);
  });

  form.on('submit', function () {
    userChangeOptionVisibility($dropdown);
    applyButton.prop('disabled', true);
    $dropdown.click();
  });
}

/**
 * Función para trackeo de uso de funcionalidad en amplitude:
 * Para cada click del usuario en los checkbox agrega el valor al arreglo correspondiente.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {void}
 * */

function trackingAmplitudeEvent($dropdown) {
  const form = $dropdown.closest('form');
  let showColumns = [], hideColumns = [];

  const updateColumns = (label, addToShow) => {
    const [addList, removeList] = addToShow ? [showColumns, hideColumns] : [hideColumns, showColumns];
    const removeIndex = removeList.indexOf(label);
    if (removeIndex !== -1) removeList.splice(removeIndex, 1);
    if (!addList.includes(label)) addList.push(label);
  };

  const personalizedResourceId = form.attr('data-body-id');
  const $fijiBody = $(`#${personalizedResourceId}.fiji-body`);

  const optionsContainer = dropdownMenuOverflow ?
    $fijiBody.find('.dropdown-menu--overflow') :
    $dropdown.find('.dropdown-menu');

  optionsContainer.on('change', 'input[type="checkbox"]', function () {
    const label = $(this).closest('label').text().trim();
    updateColumns(label, $(this).prop('checked'));
  });

  form.on('submit', function (event) {
    event.preventDefault();
    form.trigger('amplitudeTracking', {
      message: 'Hide_Show_Column',
      data: {
        'table_source': form.attr('amplitude-table-source'),
        'show_columns': showColumns,
        'hide_columns': hideColumns,
      },
    });

    showColumns = [];
    hideColumns = [];
  });
}

/**
 * Inicializa el dropdown de visibilidad de columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {void}
 */
onmount('.column-visibility', function () {
  const $dropdown = $(this);
  stopPropagationMenu($dropdown);
  userChangeOptionVisibility($dropdown);
  handleOverflowTable($dropdown);
  trackingAmplitudeEvent($dropdown);
});

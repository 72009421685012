/** @file
* Archivo para generar actualizaciones en mural
*/
import onmount, { $ } from 'onmount';
import { CKeditorVersion } from '@assets/javascripts/components/ckeditor/load';

onmount('#preview_publication_button', function () {

  $(document).on('beforeAjaxSend.ic', function (_event, ajaxSetup, _elt) {
    if (!ajaxSetup.url.includes('/employee_portal/murals/')) return;
    let content;
    if (CKeditorVersion() === '4') {
      content = CKEDITOR.instances['employee_portal_mural_message'].getData();
    }
    else {
      content = $('#employee_portal_mural_message').val();
    }
    ajaxSetup.data = ajaxSetup.data + '&employee_portal_mural%5Bmessage%5D=' +
    encodeURIComponent(content);
  });
});

// Revisa si un elemento ha sido visualizado por 3 segundos
onmount('.mural', function () {
  const observer = new IntersectionObserver((entries, _observer) => {
    entries.forEach(entry => {
      const target = entry.target;
      if (target.timerCompleted) {
        return;
      }
      if (entry.isIntersecting) {
        if (!target.timer) {
          target.timer = setTimeout(() => {
            const feedId = target.getAttribute('data-feed-item-id');
            if (feedId === undefined) { return; }
            sendAjaxRequestFIP(feedId);

            target.timerCompleted = true;
            _observer.unobserve(target);
          }, 3000);
        }
      }
      else {
        if (target.timer) {
          clearTimeout(target.timer);
          target.timer = null;
        }
      }
    });
  }, { threshold: 1.0 });

  document.querySelectorAll('.mural').forEach(el => observer.observe(el));
});

// Para abrir la galería
onmount('.gallery-container', function () {
  $(this).on('click', function () {
    const feedId = $(this).data('feed-id');
    if (feedId === undefined) { return; }
    sendAjaxRequestFIP(feedId);
  });
});

// Para el botón de ver más comentarios
onmount('.load-more-comments-container', function () {
  $(this).on('click', function () {
    const feedId = $(this).data('feed-id');
    if (feedId === undefined) { return; }
    sendAjaxRequestFIP(feedId);
  });
});

function sendAjaxRequestFIP(feedId) {
  $.ajax({
    url: `/employee_portal/feed_item_person/${feedId}`,
    type: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({
      feed_id: feedId,
    }),
  });
}

onmount('#banner-list-data', function () {
  try {
    const $bannerListData = document.querySelector('#banner-list-data');

    const $bannerListContainerMobile = $('#banner-list-container-mobile');
    const $bannerListContainerDesktop = $('#banner-list-container-desktop');

    $($bannerListContainerMobile).html($bannerListData.outerHTML); //mobile
    $($bannerListContainerDesktop).html($bannerListData.outerHTML); //desktop
  }
  catch (error) { /* empty */ }
});

onmount('.portal-comunicaciones-filter', function () {
  const url = new URLSearchParams(window.location.search);
  const type = $(this).data('default-tab');
  const standaloneMuralId = url.get('mural');
  let muralPathId = '';
  let standaloneMuralClicked = false;
  if (standaloneMuralId) {
    muralPathId = `&mural=${standaloneMuralId}`;
  }

  const $filterItem = $(`#filter-${type}`);
  const $feedLoader = $('.feed-loader');
  const $publicationsSectionContent = $('#publications-section-content');
  const $publicationFilterItem = $('.portal-comunicaciones-filter__item:not(.advanced-filter)');
  let firstLoad = true;

  const showFilterBody = function () {
    $('.filter-body')?.addClass('d-none');
    $(`#filter-${type}-body`)?.removeClass('d-none');
  };

  $publicationFilterItem.on('click', function (event) {
    $publicationFilterItem.removeClass('active');
    $(event.target).addClass('active');
    if (firstLoad) return;
    const $button = $(event.target);

    $button.trigger('amplitudeTracking', {
      message: 'Filter_Feed',
      data: {
        filter_name: $button.data('name'),
      },
      'multi-trigger': 1,
    });
  });

  if (muralPathId) {
    $filterItem.addClass('active');
    $.ajax({
      url: `/employee_portal/murals/publications?content_type=${type}&page=1&page_size=5${muralPathId}`,
      type: 'GET',
      contentType: 'application/json',
      success(data) {
        $feedLoader.addClass('d-none');
        $publicationsSectionContent.html(data);

        showFilterBody();
        $(document).trigger('hideContentEvent');

        // Si se redirige al portal con un param de un mural, se abre el sidebar automaticamente
        onmount('.standalone-mural a', function () {
          if(!standaloneMuralClicked) {
            $(this).click();
            standaloneMuralClicked = true;
          }

          onmount('#mural-aside', function () {
            const $asidebar = $(this).parents('.aside-base')[0];
            const $asidebarMask = $($asidebar).find('.aside-mask');
            const $asidebarCloseBtn = $($asidebar).find('.close-btn');
            const $overlay = $('.shepherd-modal-is-visible.shepherd-modal-overlay-container');
            const $shepherdElement = $('.shepherd-element.shepherd-enabled');
            if($overlay) {
              $overlay.css('z-index', '997');
              $shepherdElement.css('z-index', '999');
            }
            const resetTour = function () {
              if($overlay) {
                $overlay.css('z-index', '9997');
                $shepherdElement.css('z-index', '9999');
              }
            };

            $asidebarMask.on('click', function () {
              resetTour();
            });

            $asidebarCloseBtn.on('click', function () {
              resetTour();
            });
          });
        });
      },
    });
  }
  else {
    // Para la primera carga, para evitar problemas de ajax con intercooler
    onmount($filterItem, function () {
      $filterItem.click();
      firstLoad = false;
    });
  }

  $publicationFilterItem
    .on('beforeSend.ic', function () {
      $feedLoader.removeClass('d-none');
    })
    .on('complete.ic', function (event) {
      const $currentFilterItem = $(event.target);

      $feedLoader.addClass('d-none');
      $('.filter-body').addClass('d-none');
      $(`#${$currentFilterItem.attr('id')}-body`).removeClass('d-none');
    });
});

onmount('#portal-comunicaciones-content', function () {
  const $mainElement = $('#portal-comunicaciones-content');
  const $currentTab = window.location.hash.substr(1);
  const $tabLabel = $mainElement.attr('data-main-tab');
  const $menuList = $('.menu_mobile .nav-item > .nav-link');
  const getLocalMainTab = () => localStorage.getItem('local-main-tab');

  const setLocalMainTab = (tab = $tabLabel) => {
    localStorage.setItem('local-main-tab', tab);
    $(tab).addClass('show active');
    $(`${tab}-tab`).addClass('active');
  };

  const updateLocalStorage = (tab) => {
    localStorage.setItem('local-main-tab', tab);
  };

  if ($currentTab) {
    const isInArray = $menuList.toArray().some((element) => element.getAttribute('href') === `#${$currentTab}`);
    if (isInArray) {
      updateLocalStorage(`#${$currentTab}`);
    }
    else {
      getLocalMainTab() ? setLocalMainTab(getLocalMainTab()) : setLocalMainTab();
    }
  }
  else {
    setLocalMainTab();
  }

  $menuList.on('click', function (e) {
    let target = $(e.target);
    if (!target.is('a')) {
      target = target.parent();
    }
    const href = target.attr('href');
    if (href && href !== '#') {
      updateLocalStorage(href);
    }
  });
});

// Para que desaparezca el popover al seleccionar una reaccion
onmount('[class*="react-list-item"]', function () {
  $(this).on('click', function () {
    $('[data-tippy-root]').fadeOut(250, function () {
      $(this).remove();
    });
  });
});

onmount('.birthday-sidebar', async function () {
  const recognitionData = {
    'source': $(this).data('source'),
  };
  $(this).trigger('amplitudeTracking', { message: 'Open_Birthday', data: recognitionData });
});

onmount('#tasks-mobile', function () {
  const notCompletedCount = $(this).find('select').data('not-completed-count');
  const $tareasNavItemBadge = $('#tareas-tab .chip.badge');

  $tareasNavItemBadge.text(notCompletedCount);
});

onmount('.infinite-scroll-wrapper', function () {
  let loadingNextPage = false;
  const $icList = [];
  $('.content-wrapper').on('scroll', function () {
    if($('.publicaciones-section > #load-more-btn-wrapper .load-more button').length !== 0) {
      const $spaceHeight = 100; //header + padding + margin
      const $currentHeight = $('.content-wrapper .content').outerHeight()
                              - $('body').outerHeight()
                              + Number($spaceHeight);
      if($('.content-wrapper').scrollTop() >= $currentHeight
      && !loadingNextPage) {
        const $loadMoreButton = $('.publicaciones-section > #load-more-btn-wrapper .load-more button');
        const $icId = $('#load-more-btn-wrapper').attr('ic-id');
        $loadMoreButton.on('beforeSend.ic', function () {
          if (loadingNextPage) return;
          loadingNextPage = true;
          $('.feed-more-loader').removeClass('d-none');
        }).on('complete.ic error.ic', function () {
          $('.feed-more-loader').addClass('d-none');
          loadingNextPage = false;
        });
        if (!$icList.includes($icId)) { // Para evitar que se llame varias veces
          $loadMoreButton.click();
          $icList.push($icId);
        }
      }
    }
  });
});
